/* Block */
.page {
  position: relative;
  min-height: calc(var(--appHeight) - var(--headerHeight));
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% auto;
}

.mobPage {
  min-height: calc(var(--appHeight) - var(--headerHeight));
}

.contentWrapper {
  flex: 1;
}

/**
 * Modification for column layout
 * (Used to distinguish WEB and MOB styles)
 */
.columnLayout .header {
  padding: 20px 0;
}

.columnLayout .content {
  display: flex;
}

.columnLayout .footerTop {
  padding: 0;
}

.columnLayout .additionalTerms {
  padding: 20px 0 0;
  text-align: left;
}

.additionalTerms:empty {
  display: none;
}

.columnLayout .innerTabs {
  flex-shrink: 0;
  width: 100px;
  padding: 30px 0 10px;
  background: var(--themeCardLight);
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

/**
 * Modification to display sidebar
 * (Used on VOD page)
 */
.withSidebar .columnSidebar {
  display: block;
}

/* Add gradient background to overlap background image */
.page::before,
.page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page::before {
  z-index: 2;
  background: var(--themePage);
  opacity: 0.8;
}

.page::after {
  z-index: 1;
  background: linear-gradient(to top, var(--themePage) 50%, rgba(255, 255, 255, 0));
}

/* Elements */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  z-index: 3;
}

.header {
  padding: 0 0 10px;
}

.motivation {
  font: var(--themeH4);
}

.motivation:empty {
  display: none;
}

.centeredMotivation {
  text-align: center;
  font: var(--themeH2);
}

/* Main content */
.column {
  position: relative;
}

.columnSidebar {
  composes: column;
  flex-shrink: 0;
  width: 240px;
  margin-right: 40px;
  display: none;
}

.columnMain {
  composes: column;
  display: flex;
  flex-grow: 1;
  min-height: 200px;
}

.inner {
  position: relative;
}

.innerMain {
  composes: inner;
  flex-grow: 1;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;

  /* next is to prevent from stretching outside borders - keep it! */
  min-width: 1px;
}

.rightBorderRadius {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.leftBorder {
  border-left: 1px solid var(--themeDivider);
}

.innerTabs {
  composes: inner;
  transition: var(--themeTransition);
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.innerTabs:empty {
  display: none;
}

.innerContent {
  margin: 20px 40px;
}

.spacedInnerContent {
  margin: 40px;
}

.form {
  margin-top: 20px;
}

.spacedForm {
  margin-top: 40px;
}

.form:empty {
  display: none;
}

.fail {
  margin: 20px 20px 0;
}

.failLeftBorderRadius,
/* for elements that rendered through portal */
.failLeftBorderRadius > div {
  border-top-left-radius: inherit;
}

.failRightBorderRadius,
  /* for elements that rendered through portal */
.failRightBorderRadius > div {
  border-top-right-radius: inherit;
}

.fail:empty,
.footer:empty {
  display: none;
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  padding: 20px 0;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
}

.footerWithIndentTop {
  padding-top: 40px;
}

.footerWithoutIndent {
  margin: -20px 0;
}

/* NOTE: All footer markup is arriving from server. So we can't currently change anything inside */
.footer a {
  color: inherit;
  text-decoration: underline;
}

.footer a:hover {
  text-decoration: none;
}

/* States */
.hidden {
  display: none !important; /* stylelint-disable-line */
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}

/**
 * For some cause, someone from payment team hacked
 * livechat widget. We need to correct his position.
 */
/* stylelint-disable */
:global(:not(#\20)#livechat-compact-container) {
  right: auto !important;
  left: 25px !important;
  bottom: 0 !important;
}

@media (max-width: 1023px) {
  .columnSidebar {
    width: 160px;
    margin-right: 10px;
  }
  .innerContent {
    margin: 20px 15px;
  }
  .columnLayout .innerTabs {
    width: 80px;
  }
}
