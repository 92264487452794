.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-height: 480px;
  padding: 20px 40px;
}

.progress {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 10px;
  color: var(--themeTextColor);
}

.text {
  max-width: 360px;
  font: var(--themeText);
  color: var(--themeTextColorMuted);
}

@media (max-width: 1023px) {
  .content {
    padding: 20px 15px;
  }
}
