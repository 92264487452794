.page {
  display: flex;
  flex-direction: column;

  /* 20px is padding-bottom on AppLayout_mainContent */
  height: calc(var(--appHeight) - var(--headerHeight) - var(--topWrapperHeight) - 20px);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px 40px;
  text-align: center;
  background: var(--themeCard);
}

.progress {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 10px;
  color: var(--themeTextColor);
}

.text {
  font: var(--themeText);
  color: var(--themeTextColorMuted);
}
