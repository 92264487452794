.external {
  position: relative;
}

.header {
  position: fixed;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid var(--themeDivider);
  background-color: var(--themeCard);
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.main {
  padding-top: 60px;
}

.iframe {
  display: block;
  width: 100%;
  min-height: 460px;
  border: none;
  background-color: #fff;
}

.content {
  padding: 20px;
  text-align: center;
}

.title {
  padding: 0 40px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.action {
  margin-top: 10px;
  width: 100%;
}

.row {
  margin-top: 20px;
  text-align: center;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
}

.inner {
  margin-bottom: 2px;
}

/* States */
.hidden {
  display: none;
}
