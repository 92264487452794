/* Activate loader */
.active::before,
.active::after {
  position: absolute;
  z-index: 101;
  content: "";
}

.active::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.95;
  background: var(--themeCard);
}

.active::after {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background: var(--themeIconMuted);
  border-radius: var(--themeRadius);

  /* Since border adds extra space and calls strange subpixel bugs on animation */
  box-shadow: inset 0 0 0 2px var(--themeIconMuted);
  overflow: hidden;
  animation: spin 2s infinite cubic-bezier(0, 0.2, 0.8, 1);
  will-change: transform;
}

.active .wrap {
  display: block;
}

/* Elements */
.wrap {
  display: none;
  text-align: center;
  position: absolute;
  z-index: 110;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* Modifications */
.inverse::before {
  background: var(--themeCardInverse);
}

.inverse::after {
  background: var(--themeIconMutedInverse);
}

.medium.active::after {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.small.active::after {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: var(--themeRadiusSmall);
}

.micro.active::after {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
}

/* we have fixed content in spa nrm where z-index is 6 */
.underContent.active::before {
  z-index: 4;
}

.underContent.active::after {
  z-index: 5;
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
    background: var(--themeCard);
  }
  5% {
    background: linear-gradient(to top, var(--themeIconMuted) 10%, #fff 10%, #fff 100%);
  }
  10% {
    background: linear-gradient(to top, var(--themeIconMuted) 20%, #fff 20%, #fff 100%);
  }
  15% {
    background: linear-gradient(to top, var(--themeIconMuted) 30%, #fff 30%, #fff 100%);
  }
  20% {
    background: linear-gradient(to top, var(--themeIconMuted) 40%, #fff 40%, #fff 100%);
  }
  25% {
    background: linear-gradient(to top, var(--themeIconMuted) 50%, #fff 50%, #fff 100%);
  }
  30% {
    background: linear-gradient(to top, var(--themeIconMuted) 60%, #fff 60%, #fff 100%);
  }
  35% {
    background: linear-gradient(to top, var(--themeIconMuted) 70%, #fff 70%, #fff 100%);
  }
  40% {
    background: linear-gradient(to top, var(--themeIconMuted) 80%, #fff 80%, #fff 100%);
  }
  45% {
    background: linear-gradient(to top, var(--themeIconMuted) 90%, #fff 90%, #fff 100%);
  }
  50% {
    transform: rotate(0deg);
    background: var(--themeIconMuted);
  }
  100% {
    transform: rotate(180deg);
    background: var(--themeCard);
  }
}
