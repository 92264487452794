.popup {
  opacity: 0;
  visibility: hidden;
  height: 100%;
  position: relative;
  z-index: 5000; /* Popup is forever on top */
  transition: var(--themeTransition);
  display: none; /* By default popup is hidden */
}

/**
 * Elements
 */
.block {
  height: 100%;
  background: var(--themeCard);
  transform: scale(0);
  transition: 1ms transform; /* fix for webkit issues with scale and child rotation animation */
}

.header {
  display: flex;
  height: 48px;
  padding: 0 4px;
  align-items: center;
  text-align: center;
  background: var(--skinHeader);
  color: var(--themeTextColorInverse);
  box-shadow: var(--themeShadow2);
  line-height: 48px;
}

.headerContent {
  flex-grow: 1;
  color: var(--themeTextColorInverse);
  font: var(--themeH2);
  margin-right: 40px; /* Add extra space for possible absolute positioned buttons */
}

.close {
  margin-left: auto;
}

.close .iconClose {
  opacity: 0.5;
  transition: var(--themeTransition);
}

.close:hover .iconClose {
  opacity: 1;
}

.content {
  position: relative;
  min-height: 150px;
  border-radius: inherit;
}

/**
 * Modifiers
 */

/* Popup without header (transparent) */
.flat .header {
  background: transparent;
  box-shadow: none;
}

.flat .headerContent {
  color: var(--themeTextColor);
}

.transparent {
  opacity: 0 !important; /* stylelint-disable-line */
}

/* Modification, where popup have no title */
.noTitle .close {
  position: absolute;
  top: 4px;
  right: 4px;
  margin: 0;
  z-index: 10;
}

.noTitle .header {
  padding: 0;
  height: 0;
}

.noTitle .headerContent {
  display: none;
}

/* For headers, without bg - return black icons */
.noTitle .header .btn,
.flat .header .btn {
  color: var(--themeTextColor);
  background: transparent;
  box-shadow: none;
}

/* stylelint-disable */
.noTitle .header .btn:hover,
.flat .header .btn:hover,
.noTitle .header .btn:focus,
.flat .header .btn:focus {
  background: var(--themeCardLight);
}

.noTitle .header .btnIcon,
.flat .header .btnIcon {
  color: var(--themeIcon);
}

/* Change color of cross for inverse and without title */
.inverse.flat .header .btn,
.inverse.noTitle .header .btn {
  color: var(--themeTextColorInverse);
}

.inverse.flat .header .btn:hover,
.inverse.noTitle .header .btn:hover,
.inverse.flat .header .btn:focus,
.inverse.noTitle .header .btn:focus {
  background: var(--themeCardLightInverse);
}

.inverse.flat .header .btnIcon,
.inverse.noTitle .header .btnIcon {
  color: var(--themeIconInverse);
}
/* stylelint-enable */

/* Fixed modification for popup (made for WEB purposes) */
.fixed {
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  padding: 0 10px;
}

.fixed::before {
  display: inline-block;
  height: 100%;
  min-height: 100%;
  content: "";
  vertical-align: middle;
}

.fixed .block {
  height: auto; /* Revert full-height */
  display: inline-block;
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 740px;
  margin: 20px 0;
  text-align: left;
  vertical-align: middle;
  border-radius: var(--themeRadius);
  background: var(--themeCard);
  font: var(--themeText);
  box-shadow: var(--themeShadow4);
}

.fixed .header {
  box-shadow: none; /* Remove original shadow */
  text-align: left; /* Switch to left all titles on web */
  height: 60px;
  padding: 10px 20px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.fixed .close {
  margin-right: -10px; /* Move to right corner */
}

/* Popup size modifications (ONLY for fixed popup) */
.fixed.small .block {
  max-width: 500px;
}

.fixed.large .block {
  max-width: 980px;
}

.fixed.noTitle .close {
  top: 10px;
  right: 10px;
  margin: 0;
}

.fixed.noTitle .header {
  height: 0;
  padding: 0;
}

/* Inverse fixed popup */
.inverse .block {
  background: var(--themeCardInverse);
  color: var(--themeTextColorInverse);
}

/* Gallery modification */
.gallery {
  padding: 0;
}

.gallery .close {
  padding: 0;
  overflow: hidden;
}

.gallery .header {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.gallery .btn,
.gallery .btnIcon {
  width: 48px;
  height: 48px;
  margin: 17px;
}

.gallery .iconClose {
  color: var(--themeIconDisabledInverse);
  font-size: 48px;
}

.gallery .iconClose:hover {
  color: var(--themeIconInverse);
}

.gallery .block {
  border-radius: 0;
  max-width: inherit;
  margin: 0;
  height: 100%;
  background: var(--themeCardInverse);
}

.gallery .content {
  height: 100%;
}

/**
 * modification with custom header
 * e.g. extra days, holidays popups
 */

.customHeader .iconClose {
  opacity: 1;
  color: var(--themeIconInverse);
}

/**
 * Active state for popup
 * Global selectors, see App.css
 * They must be shared
 */
:global(.is-fixed-popup-active) .popup,
:global(.is-popup-active) .popup {
  opacity: 1;
  visibility: visible;
  overflow-y: scroll;
  display: block;
}

:global(.is-fixed-popup-active) .gallery,
:global(.is-popup-active) .gallery {
  overflow: hidden;
}

/**
 * Hidden state. Must be important because this class can be
 * mixed with other 'display' properties
 */
.hidden {
  display: none !important; /* stylelint-disable-line */
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
