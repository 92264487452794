/* Tooltip widget */

.default {
  --tooltipArrowSize: 8px;
  --tooltipAppearOffset: 10px;

  visibility: hidden;
  position: absolute;
  z-index: 901;
  max-width: 200px;
  margin: var(--tooltipArrowSize);
  padding: 7px 10px;
  text-transform: none;
  min-width: 120px;
  opacity: 0; /* Make block hidden by default */
  color: var(--themeTextColorInverse);
  border-radius: var(--themeRadius);
  background: var(--themeCardInverse);
  will-change: transform;
  font: var(--themeTextSmall);
  transition: var(--themeTransition);
}

.default::before {
  position: absolute;
  width: var(--tooltipArrowSize);
  height: var(--tooltipArrowSize);
  content: "";
  background: var(--themeCardInverse);
}

.default:empty {
  display: none;
}

/* Style inner links */
.default a {
  color: var(--themeTextColorInverse);
  text-decoration: underline;
}

.default a:hover {
  text-decoration: none;
}

/**
 * Position modifications
 * WARNING: Avoid to change those styles, or do it carefully :)
 * Positioned on top from target
 */
.topLeft,
.topCenter,
.topRight {
  composes: default;
  transform: translateY(var(--tooltipAppearOffset));
}

.topLeft::before,
.topCenter::before,
.topRight::before {
  top: 100%;
  transform-origin: 100% 0;
}

.topLeft.active,
.topCenter.active,
.topRight.active {
  transform: translateY(0);
}

.topLeft {
  bottom: 100%;
  left: 0;
  margin-left: 0;
}

.topLeft::before {
  left: calc(var(--tooltipArrowSize) * 3 / 2);
  transform: rotate(45deg);
}

.topCenter {
  bottom: 100%;
  left: 50%;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%) translateY(var(--tooltipAppearOffset));
}

.topCenter::before {
  left: calc(var(--tooltipArrowSize) / -3 + 50%);
  transform: rotate(45deg);
}

.topCenter.active {
  transform: translateX(-50%) translateY(0);
  will-change: contents;
}

.topRight {
  right: 0;
  bottom: 100%;
  margin-right: 0;
}

.topRight::before {
  right: var(--tooltipArrowSize);
  transform: rotate(45deg);
}

/* Positioned on bottom from target */
.bottomLeft,
.bottomCenter,
.bottomRight {
  composes: default;
  transform: translateY(calc(var(--tooltipAppearOffset) * -1));
}

.bottomLeft::before,
.bottomCenter::before,
.bottomRight::before {
  bottom: 100%;
  transform-origin: 0 100%;
}

.bottomLeft.active,
.bottomCenter.active,
.bottomRight.active {
  transform: translateY(0);
}

.bottomLeft {
  top: 100%;
  left: 0;
  margin-left: 0;
}

.bottomLeft::before {
  left: var(--tooltipArrowSize);
  transform: rotate(45deg);
}

.bottomCenter {
  top: 100%;
  left: 50%;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%) translateY(calc(var(--tooltipAppearOffset) * -1));
}

.bottomCenter::before {
  right: calc(var(--tooltipArrowSize) / 3 + 50%);
  transform: rotate(45deg);
}

.bottomCenter.active {
  transform: translateX(-50%) translateY(0);
  will-change: contents;
}

.bottomRight {
  top: 100%;
  right: 0;
  margin-right: 0;
}

.bottomRight::before {
  right: calc(var(--tooltipArrowSize) * 3 / 2);
  transform: rotate(45deg);
}

/* Positioned on left from target */
.leftTop,
.leftCenter,
.leftBottom {
  max-width: none;
  transform: translateX(var(--tooltipAppearOffset));
  white-space: nowrap;
}

.leftTop::before,
.leftCenter::before,
.leftBottom::before {
  left: 100%;
  transform-origin: 0 0;
}

.leftTop.active,
.leftCenter.active,
.leftBottom.active {
  transform: translateX(0);
}

.leftTop {
  top: 0;
  right: 100%;
  margin-top: 0;
}

.leftTop::before {
  top: 12px;
  transform: translateY(-45%) rotate(45deg);
}

.leftCenter {
  top: 50%;
  right: 100%;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateX(var(--tooltipAppearOffset)) translateY(-50%);
}

.leftCenter::before {
  top: 50%;
  transform: translateY(-75%) rotate(45deg);
}

.leftCenter.active {
  transform: translateX(0) translateY(-50%);
  will-change: contents;
}

.leftBottom {
  right: 100%;
  bottom: 0;
  margin-bottom: 0;
}

.leftBottom::before {
  bottom: 12px;
  transform: rotate(45deg);
}

/* Positioned on right from target */
.rightTop,
.rightCenter,
.rightBottom {
  max-width: none;
  transform: translateX(calc(var(--tooltipAppearOffset) * -1));
  white-space: nowrap;
}

.rightTop::before,
.rightCenter::before,
.rightBottom::before {
  right: 100%;
  transform-origin: 100% 100%;
}

.rightTop.active,
.rightCenter.active,
.rightBottom.active {
  transform: translateX(0);
}

.rightTop {
  top: 0;
  left: 100%;
  margin-top: 0;
}

.rightTop::before {
  top: 12px;
  transform: rotate(45deg);
}

.rightCenter {
  top: 50%;
  left: 100%;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateX(var(--tooltipAppearOffset)) translateY(-50%);
}

.rightCenter::before {
  top: 50%;
  transform: translateY(-25%) rotate(45deg);
}

.rightCenter.active {
  transform: translateX(0) translateY(-50%);
  will-change: contents;
}

.rightBottom {
  bottom: 0;
  left: 100%;
  margin-bottom: 0;
}

.rightBottom::before {
  bottom: 12px;
  transform: translateY(45%) rotate(45deg);
}

/* Error tooltip modification */
.error {
  composes: default;
  background: var(--skinDanger);
}

.error::before {
  background: var(--skinDanger);
}

/**
 * Tooltip for small blocks (icons, etc.).
 * See brandbook for further info
 */
.icon {
  composes: default;
  width: 180px;
  max-width: none;
}

.icon.bottomLeft,
.icon.topLeft {
  left: 50%;
  margin-left: calc(var(--tooltipArrowSize) * -1.75);
}

.icon.bottomRight,
.icon.topRight {
  right: 50%;
  margin-right: calc(var(--tooltipArrowSize) * -1.75);
}

.icon.rightTop,
.icon.leftTop {
  top: 50%;
  margin-top: calc(var(--tooltipArrowSize) * -1.75);
}

.icon.rightBottom,
.icon.leftBottom {
  bottom: 50%;
  margin-bottom: calc(var(--tooltipArrowSize) * -1.75);
}

/* Visual modifications */
.noArrow {
  composes: default;
}

.noArrow::before {
  display: none; /* Remove arrow */
}

/* Multiline. Please define width in component! */
.multiline {
  composes: default;
  white-space: normal;
}

/* Oneline tooltip */
.oneline {
  composes: default;
  white-space: nowrap;
  max-width: none;
  width: auto;
  min-width: 0;
}

/* Active state */
.active {
  visibility: visible;
  opacity: 1;
}

.tooltipSmall {
  min-width: 110px;
}
