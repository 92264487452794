/* Icons (Standart size for font-icons etc.) */
.default {
  font-size: 24px;
  display: inline-block;
  margin: -2px;
}

/* We have issue with vertical align in chrome for icons with specified parent height,
so we need override default align from top to bottom */
/* stylelint-disable */
.default::before {
  vertical-align: bottom;
}
/* stylelint-enable */

/* Modifications */
.inverse {
  composes: default;
  color: var(--themeIconInverse);
}

.muted {
  composes: default;
  color: var(--themeIconMuted);
}

.inverse.muted {
  color: var(--themeIconMutedInverse);
}

.standart {
  composes: default;
  color: var(--themeIcon);
}

.primary {
  composes: default;
  color: var(--skinPrimary);
}

.danger {
  composes: default;
  color: var(--skinDanger);
}

.success {
  composes: default;
  color: var(--skinSuccess);
}

.accent {
  composes: default;
  color: var(--skinAccent);
}

.inherit {
  composes: default;
  font-size: inherit !important; /* stylelint-disable-line */
}

.disabled {
  composes: default;
  color: var(--themeIconDisabled);
}

.inputBorder {
  composes: default;
  color: var(--themeInputBorder);
}

.icon__12 {
  composes: default;
  font-size: 12px;
  margin: -1px;
}

.icon__16 {
  composes: default;
  font-size: 16px;
  margin: -1px;
}

.icon__30 {
  composes: default;
  font-size: 30px;
  margin: -2px;
}

.icon__36 {
  composes: default;
  font-size: 36px;
  margin: -3px;
}

.icon__48 {
  composes: default;
  font-size: 48px;
  margin: -4px;
}

.icon__72 {
  composes: default;
  font-size: 72px;
  margin: -6px;
}

.icon__96 {
  composes: default;
  font-size: 96px;
  margin: -8px;
}

[dir="rtl"] .mirrored {
  transform: scaleX(-1);
}
