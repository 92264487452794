/* Steps bubbles */
.default {
  display: flex;
  align-items: center;
}

/* Elements */
.item {
  display: flex;
  align-items: center;
}

.label {
  display: flex;
  background-color: var(--themeCardLight);
  align-items: center;
  justify-content: center;
  font: var(--themeTextSmall);
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
}

.item.active .label {
  color: var(--skinPrimary);
  background: var(--skinPrimaryLite);
}

.item.passed .label {
  background: var(--skinSuccessLite);
}

.item.error .label {
  background: var(--skinDangerLite);
}

.item.passed .num,
.item.error .num {
  display: none;
}

.divider {
  background: var(--themeDivider);
  height: 1px;
  min-width: 20px;
  margin: 0 10px;
}

.text {
  color: var(--themeTextColorMuted);
  margin-left: 10px;
}

.item.active .text {
  color: var(--themeTextColor);
}

.item.passed .text {
  color: var(--themeTextColor);
}

.item.error .text {
  color: var(--skinDanger);
}

.iconPassed,
.iconError {
  display: none !important; /* stylelint-disable-line */
  font-size: 20px;
  color: var(--themeTextColorInverse);
}

.item.passed .label,
.item.error .label {
  font: var(--themeText);
}

.item.passed .iconPassed {
  color: var(--skinSuccess);
  display: block !important; /* stylelint-disable-line */
}

.item.error .iconError {
  color: var(--skinDanger);
  display: block !important; /* stylelint-disable-line */
}

.title,
.content {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.content {
  align-items: stretch;
}

.contentInner {
  flex-grow: 1;
}

/* Modifications */
.full {
  composes: default;
  justify-content: space-between;
}

.full .divider {
  flex-grow: 1;
}

/* Vertical steps (used on 'Promote My Account') */
.vertical {
  composes: default;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.vertical .item {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.vertical .divider {
  height: auto;
  width: 1px;
  min-width: 1px;
  margin: 0 25px 0 15px;
}

/* stylelint-disable-next-line */
.vertical .item:last-child .divider {
  background: transparent; /* Remove divider on last step */
}

/* Small steps (used on mob) */
.small .text,
.small .active .text {
  color: var(--themeTextColorMuted);
  font: var(--themeTextSmall);
}

.small.vertical .divider {
  margin-right: 15px;
}

.small .title,
.small .content {
  margin-bottom: 10px;
}
