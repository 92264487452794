.animation {
  background-color: var(--themeCardLight);
}

.animation::after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: url(src/packages/core/ui/components/placeholder/animatedPlaceholder.gif);
  opacity: 0.075;
}

.default {
  composes: animation;
  margin: 4px 0;
  height: 10px;
  width: 100%;
  border-radius: 4px;
  flex-shrink: 0;
}

/**
 * here and to the end are modifications for width
 * number in the end means part of 10 of parent width
 * eg text1 means width: 10%
 */
.text1 {
  composes: default;
  width: 10%;
}

.text2 {
  composes: default;
  width: 20%;
}

.text3 {
  composes: default;
  width: 30%;
}

.text4 {
  composes: default;
  width: 40%;
}

.text5 {
  composes: default;
  width: 50%;
}

.text6 {
  composes: default;
  width: 60%;
}

.text7 {
  composes: default;
  width: 70%;
}

.text8 {
  composes: default;
  width: 80%;
}

.text9 {
  composes: default;
  width: 90%;
}

/**
 * here are modifications for round elements
 * number in the end means changes in 10px increments
 * eg round4 means width: 40px,height: 40px;
 */
.round {
  composes: animation;
  background-color: var(--themeCardLight);
  border-radius: 100px;
}

.round4 {
  composes: round;
  width: 40px;
  height: 40px;
}

.round6 {
  composes: round;
  width: 60px;
  height: 60px;
}

.round8 {
  composes: round;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.round9 {
  composes: round;
  width: 90px;
  height: 90px;
}

.round10 {
  composes: round;
  width: 100px;
  height: 100px;
  border-radius: var(--themeRadiusLarge);
}

.round12 {
  composes: round;
  width: 120px;
  height: 120px;
}

.photoBase {
  composes: animation;
  border-radius: var(--themeRadius);
  margin: 0 20px 20px 0;
  display: inline-block;
}

.photo {
  composes: photoBase;
  width: 100%;
  height: 100%;
}

.photo160 {
  composes: photoBase;
  width: 160px;
  height: 160px;
}

.square {
  composes: animation;
  width: 100%;
  height: 100%;
  background-color: var(--themeCardLight);
  border-radius: var(--themeRadius);
}
