/* Select styles */
.select {
  position: relative;
  text-align: left; /* All content inside must be left aligned, everywhere */
}

/**
 * Elements
 */

.label {
  padding-top: 4px;
  font: var(--themeTextSmall);
  color: var(--themeTextColorLabel);
}

.error.label {
  color: var(--skinDanger);
}

.value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 40px;
  padding: 10px 0;
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
  color: var(--themeTextColor);
  flex-grow: 1;
  border: 0;
  outline: 0;
  background: transparent;
}

.placeholder {
  color: var(--themeTextColorMuted);
}

/* Inner content. E.g. icon with text */
.valueIcon {
  margin: -5px 0; /* Avoid growing select out of needed size */
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 24px;
  color: var(--themeIcon);
}

.valueIcon:first-child {
  margin-right: 20px;
}

.valueIcon:last-child {
  margin-left: 20px;
}

.valueText {
  flex-grow: 1;
}

.valueSearchShort {
  composes: value;
  color: var(--themeTextColorMuted);
}

.input {
  /* Default select. Will be hidden */
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -5;
  cursor: pointer;

  /* Fix for Mac Safari. Area is not clickable. */
  -webkit-appearance: menulist-button;
}

/* NativeSelect for device */
.nativeSelect .input {
  z-index: 5;
}

.nativeSelect .dropdown {
  display: none;
}

/* Counter for multiple select */
.counter {
  margin: -5px 0;
  position: relative;
}

.counter:first-child {
  margin-right: 10px;
}

.counter:last-child {
  margin-left: 10px;
}

.dropdown {
  visibility: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 120;
  top: 100%;
  right: -20px;
  left: -20px;
  max-height: 200px;
  padding: 10px 0;
  transform: translateY(-20px);
  opacity: 0;
  border-radius: var(--themeRadius);
  background: var(--themeCard);
  transition: var(--themeTransition);
  box-shadow: var(--themeShadow2);
}

.withHeightRestriction {
  max-height: 140px;
}

.dropdown:empty {
  display: none;
}

.item {
  padding: 10px 20px;
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  transition: var(--themeTransition);
  color: var(--themeTextColor);
  white-space: nowrap;
}

.wrap .item {
  white-space: normal;
}

.itemIcon {
  composes: valueIcon;
}

.itemText {
  composes: valueText;
}

.item:hover,
.item.active {
  background: var(--themeCardLight);
}

.item.disabled,
.item.active {
  cursor: default;
  color: var(--themeTextColorMuted);
}

.description,
.successText,
.errorText {
  display: block;
  overflow: hidden;
  max-height: 45px; /* We suppose, that in select will be only 2 rows and no more */
  margin-top: 2px;
  will-change: transform;
  font: var(--themeTextSmall);
}

.description {
  transform: translateY(0);
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
  color: var(--themeTextColorMuted);
}

.successText,
.errorText {
  visibility: hidden;
  max-height: 0;
  margin-top: 3px; /* As on BB - bigger spacing */
  transform: translateY(20px);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
  color: var(--skinDanger);
}

.successText {
  color: var(--skinSuccess);
}

/* Prefix and sufix for phone codes etc. */
.prefix {
  padding: 10px 0;
  line-height: 20px;
  margin-right: 10px;
}

.sufix {
  padding: 10px 0;
  line-height: 20px;
  margin-left: 10px;
}

.container {
  display: flex;
  position: relative;
  padding: 10px;
  align-items: center;
  background: var(--themeCard);
  border: 1px solid var(--themeInputBorder);
  border-radius: 20px;
  cursor: pointer;
}

.fixedHeight {
  height: 40px;
}

.upperCase {
  text-transform: uppercase;
}

/* State */

.hidden {
  display: none;
}

.col.active .dropdown,
.active .dropdown {
  visibility: visible;
  transform: translateY(-1px); /* Need to close border-bottom from input field */
  opacity: 1;
}

/* Icon */
.icon {
  transition: var(--themeTransition);
  padding-left: 10px;
  color: var(--themeIconDisabled);
  flex-shrink: 0;
}

.icon:first-child {
  /* If icon is before input - it's a static icon */
  padding-right: 10px;
  padding-left: 0;
}

.icon:hover {
  opacity: 0.5;
}

/* State container inside icon */
.iconStateDefault {
  display: inline-block;
}

.iconStateActive {
  display: none;
}

/* Inverse state container with active class from parent */
.active .iconStateDefault {
  display: none;
}

.active .iconStateActive {
  display: inline-block;
}

/* Success colored icon */
.iconSuccess {
  font-size: 0;
  color: var(--skinSuccess);
}

/* Clickable type of icon */
.iconClickable {
  cursor: pointer;
}

/* Button as icon */
.iconButton:hover {
  opacity: 1;
}

/* Global states */
.success .iconSuccess {
  font-size: 20px;
}

.success .successText {
  visibility: visible;
  max-height: 30px;
  transform: translateY(0);
  opacity: 1;
}

.success .description,
.error .description {
  visibility: hidden;
  max-height: 0;
  transform: translateY(20px);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
}

.success .successText,
.error .errorText {
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
}

.error .errorText {
  visibility: visible;
  max-height: 30px;
  transform: translateY(0);
  opacity: 1;
}

.focused.error .container,
.error .container {
  border-color: var(--skinDanger);
}

.focused.container {
  border-color: var(--skinPrimary);
}

/* Disabled state */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Inactive. The case, when only one select is available */
.inactive {
  pointer-events: none;
}

/* Select, without borders */
.noBorder {
  composes: select;
}

.noBorder .container {
  border: none;
}

/* Not bordered modification (used on search form WEB) */
.fullSize {
  composes: select;
  height: 100%;
}

.fullSize .container {
  height: 100%;
  border: none;
}

/* use only for fix native select in search on samsung browsers and chrome but can bee used elsewhere */
.fullWidth {
  width: 100%;
}

/* Inverse modification for select */
.inverse {
  composes: select;
}

.inverse .label {
  color: var(--themeTextColorLabelInverse);
}

.inverse .value {
  color: var(--themeTextColorInverse);
}

.inverse .value::placeholder {
  color: var(--themeTextColorDisabledInverse);
}

/* stylelint-disable-next-line selector-max-specificity */
.inverse .value:-webkit-autofill::placeholder,
.select:has(.container .value:-webkit-autofill) > .label {
  color: transparent;
}

.inverse .valueIcon {
  color: var(--themeIconInverse);
}

.inverse .container {
  border-color: var(--themeInputBorderInverse);
}

.inverse .description {
  color: var(--themeTextColorMutedInverse);
}

.inverse .icon {
  color: var(--themeIconInverse);
}

/* Increased title size. Used on email skin view in messenger */
.bigTitle {
  composes: select;
}

.bigTitle .value {
  font: var(--themeH2);
  padding: 7px 0 8px;
}

/**
 * Modification for dropdown that takes with from content size.
 * Used in inbox for input, that is shorter than content
 */

.fullWithDropdown {
  composes: select;
}

.fullWithDropdown .dropdown {
  left: 50%;
  right: auto;
  transform: translate(-50%, -20px);
  white-space: nowrap;
}

.fullWithDropdown.active .dropdown {
  transform: translate(-50%, -1px);
}

/* Modification for select where many selects are in one row */
.multiCol {
  composes: select;
}

.multiCol .row {
  display: flex;
  align-items: center;
}

.multiCol .col {
  flex-grow: 1;
  margin-left: 20px;
  position: relative;
}

.multiCol .col:first-child {
  margin-left: 0;
}

.multiCol .colSeparator {
  flex-grow: 0;
  color: var(--themeTextColorMuted);
}

/* Multiple select */
.multiple {
  position: relative;
}

.multiple .item {
  padding-left: 15px;
}

.multiple .item.active {
  cursor: pointer;
  color: var(--themeTextColor);
}

.multiple .itemIcon {
  opacity: 0;
}

.multiple .active .itemIcon {
  opacity: 1;
}

/* Hide on mobile devices (avoid tablets) */
@media (max-width: 736px) {
  .input {
    z-index: 5;
  }
  .dropdown {
    display: none;
  }
}

.triangle {
  display: flex;
  flex-shrink: 0;
  margin-left: 10px;
  will-change: transform;
  transition: transform var(--themeTransitionSpeed) var(--themeTransitionEase);
}
