/* Block */
.button {
  display: flex;
  position: relative;
  transform: translateZ(0); /*  Need to create new stacking context to prevent showing .icon and .text upper main context
  due-to using z-index see below
  .text,
  .icon {
    position: relative;
    z-index: 2;
  }
  https://developer.mozilla.org/ru/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
  */
  padding: 10px 15px;
  font: var(--themeH4);
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-align: center; /* Text is centered by default */
  text-decoration: none; /* Because it can be a link */
  color: var(--themeTextColor);
  border: none;
  border-radius: var(--themeRadius);
  outline: none;
  background: var(--themeButton);
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--themeInputBorder);
  transition: var(--themeTransition);
}

/* Overlay for hover state */
.button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  border-radius: inherit;
  transition: var(--themeTransition);
  background: var(--themeOverlayExtraLite);
}

.hoverable:active::after,
.hoverable:hover::after {
  opacity: 1;
}

.default.inverse::after,
.flat.inverse::after,
.flatCover.inverse::after {
  background: var(--themeCardLightInverse);
}

/* Elements */
.text,
.icon {
  position: relative;
  z-index: 2;
}

.icon {
  height: 20px; /* To prevent button height expansion */
  font-size: 24px; /* Default size from brandbook */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeIcon);
  padding-right: 9px;
  flex-shrink: 0;
}

.icon:last-child {
  padding-right: 0;
  padding-left: 9px;
}

.icon:only-child {
  padding-left: 0;
  padding-right: 0;
}

.text {
  text-align: center;
}

.subText {
  font: var(--themeTextSmall);
  text-transform: initial;
  font-weight: normal;
  color: var(--themeTextColorMutedInverse);
}

/**
 * Tag (counter) inside button
 * Used on activity button with counters
 */
.tag {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
}

/**
 * Inner wrapper item.
 * Needed for correct displaying in FF browser for 'button' tag
 */
.wrap {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.tokenWidget {
  flex-direction: column;
}

.ripple {
  display: block;
  position: absolute;
  z-index: 100;
  transform: scale(0);
  animation: ripple 750ms linear;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}

.rippleContainer {
  display: block;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Disabled state */
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

/* Modifications */

/* Inverse default button */
.inverse {
  composes: button;
  background: var(--themeTextColorInverse);
  color: var(--themeTextColorInverse);
  box-shadow: none;
}

.inverse .text,
.inverse .icon {
  color: var(--themeIconInverse);
}

/* Muted default button */
.muted {
  composes: inverse;
  background: var(--themeIcon);
}

/* Underline button */
.underline {
  background: none;
  font-size: var(--themeText);
  color: var(--themeTextColorMuted);
  text-decoration: underline;
  cursor: pointer;
}

.underline:hover {
  text-decoration: none;
}

/* Primary button */
.primary {
  composes: button;
  color: var(--themeTextColorInverse);
  background: var(--skinPrimary);
  box-shadow: none;
}

.primary .icon {
  color: var(--themeIconInverse);
}

/* Inverse primary button */
.primary.inverse {
  color: var(--themeTextColorInverse);
  background: var(--skinPrimary);
}

.primary.inverse .icon {
  color: var(--themeIconInverse);
}

/* Pay button */
.pay {
  composes: button;
  color: var(--themeTextColorInverse);
  background: var(--skinSuccess);
  box-shadow: none;
}

.pay .icon {
  color: var(--themeIconInverse);
}

/* Inverse pay button */
.pay.inverse {
  color: var(--themeTextColorInverse);
  background: var(--skinSuccess);
}

.pay.inverse .icon {
  color: var(--themeIconInverse);
}

/* Danger button */
.danger {
  composes: button;
  color: var(--themeTextColorInverse);
  background: var(--skinDanger);
  box-shadow: none;
}

.danger .icon {
  color: var(--themeIconInverse);
}

.danger .ripple {
  color: rgba(255, 255, 255, 0.1);
}

/* Flat button */
.flat {
  composes: button;
  color: var(--themeTextColor);
  background: transparent;
  text-decoration: underline;
  box-shadow: none;
}

.flat .text {
  text-decoration: underline;
}

.flat .icon {
  color: var(--themeIcon);
}

.flat .ripple {
  background: rgba(0, 0, 0, 0.1);
}

/* Inverse flat button */
.flat.inverse {
  color: var(--themeTextColorInverse);
  background: transparent;
}

.searchGallery.flat.inverse {
  padding: 0 20px;
  min-width: 200px;
}

.flat.inverse .icon,
.flat.inverse .text {
  color: var(--themeIconInverse);
}

.flat.inverse .ripple {
  background: rgba(255, 255, 255, 0.1);
}

/* Flat cover button */
.flatCover {
  composes: button;
  color: var(--themeTextColorCover);
  background: var(--themeIconInverse);
  box-shadow: none;
}

.flatCover .icon {
  color: var(--themeIconCover);
}

.flatCover .ripple {
  background: rgba(0, 0, 0, 0.1);
}

/* Inverse flat cover button */
.flatCover.inverse {
  color: var(--themeTextColorCoverInverse);
  background: var(--themeIcon);
}

.flatCover.inverse .icon {
  color: var(--themeIconCoverInverse);
}

.flatCover.inverse .ripple {
  background: rgba(255, 255, 255, 0.1);
}

/* Narrow style button */
.narrow {
  font: var(--themeTextSmall);
  line-height: 1;
  padding: 12px 10px;
  text-transform: none;
}

.narrow .icon {
  padding: 0;
}

.narrow .wrap {
  flex-direction: column;
  justify-content: center;
}

.narrow .text {
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
  text-decoration: none;
}

/* Rounded button */
.round {
  composes: button;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: var(--themeRadius);
}

.round .icon {
  padding: 0;

  /* Need to make icon on center on MAC Safari devices */
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.round .icon:first-child,
.round .icon:last-child {
  padding: 0;
}

.round .rippleContainer {
  border-radius: 50%;
}

.camWidget {
  flex-direction: column;
}

/* Square button. Identical to round, but have standart button radiuses */
.square {
  composes: round;
  border-radius: var(--themeRadius);
}

/* Size modifications */
.small {
  composes: button;
  box-shadow: 0 0 0 1px var(--themeInputBorder);
  padding: 3px 10px;
}

.big {
  composes: button;
  box-shadow: 0 0 0 1px var(--themeInputBorder);
  padding: 15px 20px;
}

/* Medium round button */
.round.medium {
  width: 60px;
  height: 60px;
}

/* Big round button */
.round.big {
  width: 80px;
  height: 80px;
}

.round.big .icon {
  font-size: 32px;
}

/* Special modifications */
.textLeft {
  composes: button;
  box-shadow: 0 0 0 1px var(--themeInputBorder);
  justify-content: flex-start;
}

.textLeft .text {
  text-align: left;
}

.textLeft .wrap {
  justify-content: flex-start;
}

.inline {
  display: inline-flex;
}

/**
 * Overwrite outer spaces for narrow button
 * Used on search for going out of canvas from container.
 */
.beyondBorders {
  padding: 6px 4px;
  margin: -6px;
}

/**
 * For buttons, that must be NOT BIGGER than stadart BB button
 * WARNING: Don't use with size modifications
 */
.fixedHeight {
  height: 40px;
  padding: 2px 10px;
  line-height: normal;
}

.fixedHeight .text {
  overflow: hidden; /* Needed to hide overflowing text from button */
  text-overflow: ellipsis;
  max-height: 40px;
}

.textWrap .text {
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* use for make dark styled button */
.dark {
  composes: button;
  box-shadow: 0 0 0 1px var(--themeInputBorder);
  background: var(--themeIcon);
}

/* Locked state for button (Added via JS) */
.partlyDisabled {
  opacity: 0.5;
  filter: grayscale(100%);
}

/* Loading state for button (Added via JS) */
.loading {
  opacity: 0.5;
  filter: grayscale(100%);
  pointer-events: none;
}

.loading.disabledInteraction {
  pointer-events: auto;
}

.hidden {
  display: none;
}

/* Keyframe for ripple button */
@keyframes ripple {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes progressBar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@media (max-width: 1060px) {
  /* Hide titles for tablet devices */
  .round .text {
    display: none;
  }
}
