/* Activate loader */
.active::before,
.active::after {
  position: absolute;
  z-index: 101;
  content: "";
}

.active::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.95;
  background: var(--themeCard);
}

.active::after {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation: spin 1s infinite easeInOutBack;
  border: 2px solid var(--themeDivider);
  border-top-color: var(--themeIconMuted);
  border-radius: 50%;
  will-change: transform;
}

.active .wrap {
  display: block;
}

/* Elements */
.wrap {
  display: none;
  text-align: center;
  position: absolute;
  z-index: 110;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* Modifications */
.inverse::before {
  background: var(--themeCardInverse);
}

.inverse::after {
  border-color: var(--themeDividerInverse);
  border-top-color: var(--themeIconMutedInverse);
}

.medium.active::after {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.small.active::after {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

.micro.active::after {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
}

/* we have fixed content in spa nrm where z-index is 6 */
.underContent.active::before {
  z-index: 4;
}

.underContent.active::after {
  z-index: 5;
}

/* Loader animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
