/* Table styles module */
.container {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  border-bottom: 1px solid var(--themeDivider);
}

.item {
  color: var(--themeTextColor);
}

.itemMuted {
  composes: item;
  color: var(--themeTextColorMuted);
}
