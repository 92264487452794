/* Accordion widget */
.default {
  position: relative;
}

.container {
  border-bottom: 1px solid var(--themeDivider);
}

.container:first-child {
  border-top: 1px solid var(--themeDivider);
}

/* Opener-closer for accordion */
.toggle {
  text-transform: uppercase;
  position: relative;
  padding: 15px 0;
  font: var(--themeH4);
}

/* Corner */
.toggle::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  margin: auto 0;
  content: "";
  border-top: 5px solid var(--themeIcon);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  will-change: transform;
  transition: var(--themeTransition);
}

/* Open state */
.toggled .toggle::after {
  transform: scale(1, -1);
}

/* Main content */
.content {
  margin-bottom: 24px;
  display: none;
}

.toggled .content {
  display: block;
}

/* Modifications */
.unspaced {
  composes: default;
}

.unspaced .content {
  margin: 0;
}
