/* Radio */
.default {
  display: flex;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  transform: translateZ(0); /*  Need to create new stacking context to prevent showing .icon upper main context
  due-to using z-index see below
  https://developer.mozilla.org/ru/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
  */
}

/* Elements */
.input {
  /* Real checkbox or radio box. Must be hidden */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
}

.icon {
  display: block;
  position: relative;
  z-index: 2;
  font-size: 24px;
  background: transparent;
  color: var(--themeIcon);
  transition: var(--themeTransition);
}

.container {
  position: relative;
  flex-shrink: 0;
}

.container::after,
.container::before {
  /* Ripple */
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  content: "";
  border-radius: 50%;
  background: transparent;
  will-change: transform;
}

.container::before {
  opacity: 0.25;
}

.container::after {
  z-index: 0;
  transition: var(--themeTransition);
}

.label {
  margin-left: 20px;
  color: var(--themeTextColorLabel);
  flex-grow: 1;
}

/* States */
.hover .container::after {
  background: var(--themeCardLight);
  transform: scale(2);
}

.active.hover .container::after {
  background: var(--skinPrimaryLite);
}

.active .icon {
  color: var(--skinPrimary);
}

/* translateZ(0) is fix for render on iphone SE */
.active .icon::after {
  transform: translateZ(0);
}

/* Focused state */
/* stylelint-disable selector-max-specificity */
.focused .container::before,
.default:active .container::before {
  background: var(--themeTextColorLabel);
  transition: all 300ms var(--themeTransitionEase);
}

.active.focused .container::before {
  background: var(--skinPrimary);
}

.animated .container::before {
  background: var(--themeTextColorLabel);
  animation: wave 300ms linear forwards;
}

.animated.active .container::before {
  background: var(--skinPrimary);
}
/* stylelint-enable */

/* Disabled state */
.disabled {
  pointer-events: none;
  cursor: default;
}

.disabled .icon {
  color: var(--themeIconDisabled);
}

.disabled:active .container {
  display: none;
}

/* Inverse state */
.inverse {
  composes: default;
}

.inverse .icon {
  color: var(--themeIconDisabledInverse);
}

.inverse .label {
  color: var(--themeTextColorLabelInverse);
}

/* stylelint-disable */
.inverse.animated.active .container::before,
.inverse.focused .container::before {
  color: var(--themeIconInverse);
}

.inverse.active.hover .container::after,
.inverse.hover .container::after {
  background: var(--themeCardLightInverse);
}

/* stylelint-enable */

.inverse.active .icon {
  color: var(--themeIconInverse);
}

.inverse.disabled .icon {
  opacity: 1;
}

/* Row modification */
.row {
  width: 100%;
  flex-direction: row-reverse;
}

.row .label {
  margin-left: 0;
  margin-right: 8px;
}

/* Used in case if there is input without text. Avoid flex etc. */
.single {
  composes: default;
  display: block;
}

/* When multiline text must wrap input */
.flowInput {
  composes: default;
  display: inline;
}

.flowInput .container {
  float: left;
  margin-right: 10px;
}

.flowInput .label {
  margin-left: 0;
}

@keyframes wave {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
    opacity: 0.25;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
