.loader {
  position: relative;
  height: 100%;
  width: 100%;
}

.withOverlay::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.95;
  background: var(--themeCard);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  composes: centered;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
}

.container.top {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.container.bottom {
  transform: translate(-50%, -50%) rotate(90deg);
}

.container.left {
  transform: translate(-50%, -50%) rotate(180deg);
}

.container.large {
  max-width: 120px;
  max-height: 120px;
}

.rotate {
  animation: rotatefull 9s 0s infinite linear forwards;
}

.circle {
  stroke: var(--themeDivider);
  fill: none;
  stroke-width: 2;
}

.shareCircle {
  composes: circle;
  transition: stroke-dashoffset 0.5s ease-out;
  stroke: var(--skinPrimary);
}

.percentage {
  composes: centered;
  z-index: 2;
  font: var(--themeH2);
}

.percentage.large {
  font: var(--themeH1);
}

.percentage.small {
  font: var(--themeH3);
}

.primaryColorValue.percentage {
  color: var(--skinPrimary);
}

.inverse {
  color: var(--themeTextColorInverse);
  font: var(--themeH3);
}

.inverseLiner {
  stroke: var(--themeDividerInverse);
}

.extraDiscount {
  stroke: var(--themeTextColorInverse);
}

.microIncognito {
  stroke: var(--skinHeader);
}

.moreLikes,
.freeCommunication {
  stroke: var(--skinDanger);
}

.searchQueen,
.priorityMan {
  stroke: var(--skinPrimary);
}

.sexAlarm,
.flirtAlarm {
  stroke: var(--skinAccent);
}

.topInChat {
  stroke: var(--skinWarning);
}

.superBoost {
  stroke: var(--skinSuccess);
}

/* inverse variation */
.inverseCircle.microIncognito {
  stroke: var(--themeIconMutedInverse);
}

@keyframes rotatefull {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  99%,
  100% {
    transform: rotate(360deg);
  }
}
