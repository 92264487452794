.external {
  position: relative;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.iframe {
  display: block;
  width: 100%;
  height: 460px;
  border: none;
}

.content {
  padding: 40px 20px;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.action {
  min-width: 180px;
}

.back {
  composes: action;
  margin-right: 10px;
}

.abort {
  composes: action;
  margin-left: 10px;
}

.row {
  margin-top: 20px;
  text-align: center;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
}

.inner {
  margin-bottom: 2px;
}

/* States */
.hidden {
  display: none;
}

.info {
  margin-top: 40px;
}
