.card {
  color: var(--themeTextColor);
  border-radius: var(--themeRadius);
  background: var(--themeCard);
  box-shadow: var(--themeShadow1);
}

.cardLight {
  composes: card;
  background: var(--themeCardLight);
}

.inverse {
  composes: card;
  color: var(--themeTextColorInverse);
  background: var(--themeCardInverse);
}

.boundless {
  composes: card;
  border-radius: 0;
}

.withoutShadow {
  box-shadow: none;
}

.inner {
  composes: card;
  padding: 10px 20px;
  margin-bottom: 20px;
}

/* Elements */
.content {
  padding: 40px;
}

.aside {
  color: var(--themeTextColorMuted);
  background: var(--themeCardLight);
  flex-shrink: 0;
  padding: 40px;
}

.asideLeft {
  composes: aside;
  border-right: 1px solid var(--themeDivider);
}

.asideRight {
  composes: aside;
  border-left: 1px solid var(--themeDivider);
}
