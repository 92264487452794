/* Tabs widget */
.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

/* Modifications */
.containerStart {
  composes: container;
  justify-content: flex-start;
}

.containerMultiline {
  composes: container;
  height: 100%;
}

.containerMultiline .item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

/* Elements */
.item {
  padding: 9px 20px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--themeTextColor); /* By design it must be Flat button color */
  border-bottom: 2px solid transparent;
  font: var(--themeH4);
  transition: var(--themeTransition);
}

.fullWidth {
  width: 100%;
}

.fullWidth .item {
  flex-grow: 1;
}

.halfWidth {
  flex-basis: 50%;
}

.item.active {
  color: var(--skinPrimary);
  border-bottom-color: var(--skinPrimary);
}

@media (min-width: 768px) {
  .item:hover {
    background: var(--themeCardLight);
  }
}

/* Hide tab */
.hidden {
  display: none;
}

.link {
  color: var(--themeTextColor);
}

.active .link {
  color: var(--skinPrimary);
}

@media (max-width: 800px) {
  .item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
