/* Badges, counters */
.default {
  display: inline-block;
  position: relative;
  min-width: 20px;
  font: 12px/20px var(--themeFontFamily);
  text-align: center;
  color: var(--themeTextColorInverse);
  border-radius: 10px;
  background: var(--themeTag);
  padding: 0 7px;
  transition: var(--themeTransition);
}

.default:empty {
  display: none; /* Hide container if there is no counters */
}

/* Modifications */
.pay,
.live,
.saving,
.online,
.success,
.trusted {
  composes: default;
  background: var(--skinSuccess);
}

.accent {
  composes: default;
  background: var(--skinAccent);
}

.popular,
.primary,
.tribe {
  composes: default;
  background: var(--skinPrimary);
}

.inverse {
  composes: default;
  background: var(--themeTagInverse);
  color: var(--skinPrimary);
}
