/* Popover widget */

.popover {
  --popoverArrowSize: 10px;
  --popoverAppearOffset: 10px;

  visibility: hidden;
  position: absolute;
  z-index: 500;
  width: 280px;
  margin: var(--popoverArrowSize);
  padding: 10px 20px;
  cursor: default;
  text-transform: none;
  opacity: 0; /* Make block hidden by default */
  color: var(--themeTextColor);
  border-radius: var(--themeRadius);
  background: var(--themeCard);
  will-change: transform;
  font: var(--themeText);
  transition: var(--themeTransition);
  box-shadow: var(--themeShadow3);
}

.hover {
  z-index: 1501;
}

.popover::before {
  position: absolute;
  width: var(--popoverArrowSize);
  height: var(--popoverArrowSize);
  content: "";
  background: var(--themeCard);
  z-index: -1; /* @important: - hide triangle under content */
  border-radius: 3px;
}

/**
 * Position modifications
 * WARNING: Avoid to change those styles, or do it carefully :)
 * Positioned on top from target
 */
.topLeft,
.topCenter,
.topRight {
  composes: popover;
  transform: translateY(var(--popoverAppearOffset));
}

.topLeft::before,
.topCenter::before,
.topRight::before {
  top: 100%;
  transform-origin: 100% 0;
  box-shadow: -4px -4px 0 4px var(--themeCard), 2px 2px 8px 0 rgba(0, 0, 0, 0.1), -4px -4px 0 4px var(--themeCard);
}

.topLeft.active,
.topCenter.active,
.topRight.active {
  transform: translateY(0);
}

.topLeft {
  bottom: 100%;
  left: 0;
  margin-left: 0;
}

.topLeft::before {
  left: calc(var(--popoverArrowSize) * 3 / 2);
  transform: rotate(45deg);
}

.topCenter {
  bottom: 100%;
  left: 50%;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%) translateY(var(--popoverAppearOffset));
}

.topCenter::before {
  left: calc(50% - var(--popoverArrowSize) / -3);
  transform: rotate(45deg);
}

.topCenter.active {
  transform: translateX(-50%) translateY(0);
  will-change: contents;
}

.topRight {
  right: 0;
  bottom: 100%;
  margin-right: 0;
}

.topRight::before {
  right: var(--popoverArrowSize);
  transform: rotate(45deg);
}

/* Positioned on bottom from target */
.bottomLeft,
.bottomCenter,
.bottomRight {
  composes: popover;
  transform: translateY(calc(var(--popoverAppearOffset) * -1));
}

.bottomLeft::before,
.bottomCenter::before,
.bottomRight::before {
  bottom: 100%;
  transform-origin: 0 100%;
  box-shadow: 4px 4px 0 4px var(--themeCard), -2px -2px 8px 0 rgba(0, 0, 0, 0.1), 4px 4px 0 4px var(--themeCard);
}

.bottomLeft.active,
.bottomCenter.active,
.bottomRight.active {
  transform: translateY(0);
}

.bottomLeft {
  top: 100%;
  left: 0;
  margin-left: 0;
}

.bottomLeft::before {
  left: var(--popoverArrowSize);
  transform: rotate(45deg);
}

.bottomCenter {
  top: 100%;
  left: 50%;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%) translateY(calc(var(--popoverAppearOffset) * -1));
}

.bottomCenter::before {
  right: calc(50% - var(--popoverArrowSize) / 3);
  transform: rotate(45deg);
}

.bottomCenter.active {
  transform: translateX(-50%) translateY(0);
  will-change: contents;
}

.bottomRight {
  top: 100%;
  right: 0;
  margin-right: 0;
}

/* for centering popover on profile menu */
.bottomRightMargin {
  margin-right: var(--popoverArrowSize);
}

.bottomRight::before {
  right: calc(var(--popoverArrowSize) * 3 / 2);
  transform: rotate(45deg);
}

/* Positioned on left from target */
.leftTop,
.leftCenter,
.leftBottom {
  transform: translateX(var(--popoverAppearOffset));
}

.leftTop::before,
.leftCenter::before,
.leftBottom::before {
  left: 100%;
  transform-origin: 0 0;
  box-shadow: -4px 4px 0 4px var(--themeCard), 2px -2px 10px 0 rgba(0, 0, 0, 0.1), -4px 4px 0 4px var(--themeCard);
}

.leftTop.active,
.leftCenter.active,
.leftBottom.active {
  transform: translateX(0);
}

.leftTop {
  top: 0;
  right: 100%;
  margin-top: 0;
}

.leftTop::before {
  top: 12px;
  transform: translateY(-45%) rotate(45deg);
}

.leftCenter {
  top: 50%;
  right: 100%;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateX(var(--popoverAppearOffset)) translateY(-50%);
}

.leftCenter::before {
  top: 50%;
  transform: translateY(-75%) rotate(45deg);
}

.leftCenter.active {
  transform: translateX(0) translateY(-50%);
  will-change: contents;
}

.leftBottom {
  right: 100%;
  bottom: 0;
  margin-bottom: 0;
}

.leftBottom::before {
  bottom: 12px;
  transform: rotate(45deg);
}

/* Positioned on right from target */
.rightTop,
.rightCenter,
.rightBottom {
  transform: translateX(calc(var(--popoverAppearOffset) * -1));
}

.rightTop::before,
.rightCenter::before,
.rightBottom::before {
  right: 100%;
  transform-origin: 100% 100%;
  box-shadow: 4px -4px 0 4px var(--themeCard), -2px 2px 10px 0 rgba(0, 0, 0, 0.2), 4px -4px 0 4px var(--themeCard);
}

.rightTop.active,
.rightCenter.active,
.rightBottom.active {
  transform: translateX(0);
}

.rightTop {
  top: 0;
  left: 100%;
  margin-top: 0;
}

.rightTop::before {
  top: 12px;
  transform: rotate(45deg);
}

.rightCenter {
  top: 50%;
  left: 100%;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateX(var(--popoverAppearOffset)) translateY(-50%);
}

.rightCenter::before {
  top: 50%;
  transform: translateY(-25%) rotate(45deg);
}

.rightCenter.active {
  transform: translateX(0) translateY(-50%);
  will-change: contents;
}

.rightBottom {
  bottom: 0;
  left: 100%;
  margin-bottom: 0;
}

.rightBottom::before {
  bottom: 12px;
  transform: translateY(45%) rotate(45deg);
}

/**
 * Tooltip for small blocks (icons, etc.).
 * See brandbook for further info
 */
.icon {
  composes: popover;
}

.icon.bottomLeft,
.icon.topLeft {
  left: 50%;
  margin-left: calc(var(--popoverArrowSize) * -1.75);
}

.icon.bottomRight,
.icon.topRight {
  right: 50%;
  margin-right: calc(var(--popoverArrowSize) * -1.75);
}

.icon.rightTop,
.icon.leftTop {
  top: 50%;
  margin-top: calc(var(--popoverArrowSize) * -1.75);
}

.icon.rightBottom,
.icon.leftBottom {
  bottom: 50%;
  margin-bottom: calc(var(--popoverArrowSize) * -1.75);
}

/* Elements */
.item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  z-index: 10; /* Need to overlap arrow */
  padding: 10px 20px;
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
  cursor: pointer;
  color: var(--themeTextColor);
  width: 100%;
  background: transparent;
  text-align: left;
  border: 0;
  appearance: none;
  box-shadow: none;
  transition: var(--themeTransition);
}

.item.active,
.item:hover {
  color: var(--themeTextColor);
  background: var(--themeCardLight);
}

.item:empty {
  display: none;
}

.itemUnspaced {
  composes: item;
  padding: 0;
}

.itemIcon {
  margin-right: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 24px;
}

.textSub {
  color: var(--themeTextColorMuted);
  font: var(--themeTextSmall);
}

.counter {
  margin-left: 10px;
}

/* Visual modifications */
.noArrow {
  composes: popover;
}

.noArrow::before {
  display: none; /* Remove arrow */
}

/* Dropdown menu modification */
.menu {
  composes: popover;
  padding: 10px 0;
  width: auto;
}

/* Used only in search row (looks like dropdown appearing below row) */
.dropdown {
  composes: popover;
  margin: 0;
  transform: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: auto;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 0;
}

.dropdown::after,
.dropdown::before {
  /* Remove arrow */
  position: absolute;
  z-index: 5;
  right: 0;
  left: 0;
  width: auto;
  height: 5px;
  content: "";
  transform: none;
}

.dropdown::after {
  right: -5px;
  bottom: 100%;
  left: -5px;
  background: var(--themeCard);
}

.dropdown::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)); /* Transparent doesn't work in Safari and iPad */
}

/* Unspaced modification for popover */
.unspaced {
  composes: popover;
  padding: 0;
}

/* With maximal paddings around */
.extraSpaced {
  composes: popover;
  padding: 40px;
}

/* Only text popover, must have identical paddings on each side */
.expanded,
.text {
  composes: popover;
  padding: 20px;
}

/* Popover with increased width */
.large {
  composes: popover;
  width: 380px;
}

.wide {
  composes: popover;
  width: 540px;
}

/* Main menu. Placed in top-right corner (web) */
.mainMenu.icon {
  width: 220px;
  margin-right: -40px;
}

.mainMenu.icon::before {
  right: 37px;
}

/* dissaprove popover on mob/ must have low z-index value */
.disapprove {
  z-index: 5;
}

/**
 * Specific smiles popover.
 * There is a case in inbox, when popover is placed inside 'overflow: hidden' and goes cutted.
 */
.smiles {
  margin-left: -50px;
}

.smiles::before {
  left: 67px;
}

/* when is clicked hovered modification is hidden, e.g. userMenu */
.active .hovered {
  visibility: hidden;
  opacity: 0;
}

/* On flirtcast widget - popover must be a little smaller :) */
.short,
.flirtcast {
  width: 220px;
}

/* CVV popover */
.fullWidth {
  width: calc(var(--appWidth) - 40px); /* side padding */
}

.fullWidth.icon.topRight {
  right: 0;
  margin-right: 0;
}

/* Small text inside popover */
.smallText {
  font: var(--themeTextSmall);
  line-height: 18px;
}

/**
 * Temporary solution for popovers that are placed inside
 * block with applied 'overflow: hidden;'.
 * Just to avoid extra scrolling when popover is closed.
 * DON'T USE THIS IN ANY CASE.
 */
.insideOverflow {
  display: none;
}

.active .insideOverflow {
  display: block;
}

/* Active state */
.active {
  visibility: visible;
  opacity: 1;
}

.active:empty {
  display: none;
}

.disabled {
  /* We need this class for notification bookmark */
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 800px) {
  .small {
    width: 260px;
  }
}

@media (max-width: 1023px) {
  .bottomLeft:not(.fullWidth) {
    left: 0;
    margin-left: -90px;
  }
  .bottomLeft:not(.fullWidth)::before {
    left: 95px;
  }
}
