/* Input styles */
.input {
  position: relative;
  text-align: left; /* All content inside must be left aligned, everywhere */
  padding-top: 20px;
}

/**
 * Elements
 */
.label {
  position: absolute;
  top: 30px;
  padding-left: 10px;
  font: var(--themeText);
  color: var(--themeTextColorLabel);
  transition: var(--themeTransition);
  z-index: 1;
}

.touched .label {
  animation: blur var(--labelAnimationSpeed) var(--themeTransitionEase) both;
}

.value {
  /* Real input */
  width: 100%;
  min-height: 40px;
  padding: 10px;
  font: var(--themeFontSize) / 20px var(--themeFontFamily);
  appearance: none;
  color: var(--themeTextColor);
  border: 0;
  border-radius: inherit;
  background: none;
  box-shadow: none;
  flex-grow: 1;
  transition: var(--themeTransition);
  outline: 0;
  resize: none;
  z-index: 2;
}

.value:-webkit-autofill,
.value:-webkit-autofill:hover,
.value:-webkit-autofill:focus,
.value:-webkit-autofill:active {
  -webkit-text-fill-color: var(--themeTextColor);
  box-shadow: inset 0 0 0 40px var(--skinPrimaryLite);
}

.value::placeholder {
  color: var(--themeTextColorDisabled);
  opacity: 0;
  transition: opacity 65ms var(--themeTransitionEase);

  /* to fix dirty overflowed placeholders in textarea */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.focused .prefix,
.focused .sufix,
.withValue .prefix,
.withValue .sufix,
.value:focus::placeholder {
  transition: opacity var(--themeTransitionSpeed) var(--themeTransitionEase) 125ms;
  opacity: 1;
}

.value:disabled {
  pointer-events: none;
  cursor: default;
}

.valueUpdateLine {
  /* Modification of value used on update line */
  composes: value;
  height: 40px;
  padding: 10px 20px 0 0;
  resize: none;
}

.description,
.successText,
.errorText {
  display: block;
  overflow: hidden;
  max-height: 60px; /* We suppose, that in input will be only 4 rows and no more */
  margin-top: 2px;
  will-change: transform;
  font: var(--themeTextSmall);
}

.description {
  transform: translateY(0);
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
  color: var(--themeTextColorMuted);
}

.successText,
.errorText {
  visibility: hidden;
  max-height: 0;
  margin: 4px 0 0 4px;
  transform: translateY(20px);
  opacity: 0;

  /**
   * Transition for hide validation errors on some IOS
   * generates bugs in displaying the button above the keyboard
   *
   * @see PaymentPageActionPositioner.js
   */
  transition: none;
  color: var(--skinDanger);
}

.successText:empty,
.errorText:empty {
  display: none;
}

.successText {
  color: var(--skinSuccess);
}

.descriptionLong {
  composes: description;
  max-height: 105px;
}

.errorTextLong {
  composes: errorText descriptionLong;
}

.successTextLong {
  composes: successText descriptionLong;
}

/* Prefix and sufix for phone codes etc. */
.prefix {
  padding: 10px 0;
  line-height: 20px;
  opacity: 0;
  margin-right: 10px;
}

.sufix {
  padding: 10px 0;
  line-height: 20px;
  opacity: 0;
  margin-left: 10px;
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  background: var(--themeCard);
  border: 1px solid var(--themeInputBorder);
  border-radius: 20px;
}

.fixedHeight {
  height: 40px;
  overflow: hidden;
}

.icon {
  display: flex;
  position: relative;
  color: var(--themeIcon);
  transition: var(--themeTransition);
  padding: 0;
  padding-left: 10px;
  user-select: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  flex-shrink: 0;
}

.icon:first-child {
  /* If icon is before input - it's a static icon */
  padding-right: 10px;
  padding-left: 0;
  color: var(--themeIconDisabled);
}

/* State container inside icon */
.iconStateDefault {
  display: inline-flex;
}

.iconStateActive {
  display: none;
}

/* Inverse state container with active class from parent */
.active .iconStateDefault {
  display: none;
}

.active .iconStateActive {
  display: inline-flex;
}

/* Success colored icon */
.iconSuccess {
  composes: icon;
  font-size: 0;
  color: var(--skinSuccess);
}

/* Error colored icon */
.iconError {
  composes: icon;
  font-size: 0;
  color: var(--skinDanger);
}

/* Clickable type of icon */
.iconClickable {
  composes: icon;
  padding: 0 10px 0 0;
  cursor: pointer;
}

.iconBig {
  composes: icon;
  width: 40px;
  height: 40px;
  align-items: center;
}

/* Button as icon */
.iconButton {
  composes: icon;
}

.iconButton:hover {
  opacity: 1;
}

.withValue .label,
.focused .label {
  top: 2px;
  padding-left: 4px;
  font: var(--themeTextSmall);
}

/* Global states */
/* If the label should change color with focus */
.focused .label,
.focused.error .label,
.focused.error.withValue .label {
  color: var(--skinPrimary);
  animation: focus var(--labelAnimationSpeed) var(--themeTransitionEase) both;
}

.withValue.focused .label,
.withValue .label {
  animation: none;
}

.error.withValue .label {
  color: var(--skinDanger);
}

.success .iconSuccess {
  font-size: 20px;
}

.success .successText {
  visibility: visible;
  max-height: 60px;
  transform: translateY(0);
  opacity: 1;
}

.success .description,
.error .description {
  visibility: hidden;
  max-height: 0;
  transform: translateY(20px);
  opacity: 0;
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase);
}

.success .successText,
.error .errorText {
  transition: var(--themeTransitionSpeed) var(--themeTransitionEase) var(--themeTransitionSpeed);
}

.error .errorText {
  visibility: visible;
  max-height: 60px;
  transform: translateY(0);
  opacity: 1;
}

.error .errorTextLong {
  max-height: 105px;
}

.focused.error .container,
.primary.error .container,
.error .container {
  border-color: var(--skinDanger);
}

.focused .container,
.primary .container {
  border-color: var(--skinPrimary);
}

/* Disabled state */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Card holder field should be visible to be autofilled by browser. */
.autofillHidden {
  position: absolute;
  left: -99999px;
  top: -99999px;
}

/**
 * Modifications
 */

/* Clickable input */
.clickable {
  cursor: pointer;
}

/* Input, without borders */
.noBorder {
  composes: input;
}

.noBorder .container {
  border: none;
}

.noLabel {
  composes: input;
  padding-top: 0;
}

/* When input dont have label it mus have visible placeholder */
.noLabel .value::placeholder {
  opacity: 1;
}

.noLabel .value:focus::placeholder {
  opacity: 0;
}

/* Not bordered modification for textarea mainly to stretch to height */
.stretched {
  composes: input;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.stretched .container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Not bordered modification (used on search form WEB) */
.fullSize {
  composes: input;
  height: 100%;
}

.fullSize .container {
  height: 100%;
  border: none;
}

/* For inputs, placed inside flexboxes */
.fullWidth {
  composes: input;
  width: 100%;
}

/* Used when there is static content inside. Needed to hide content under dots */
.static {
  composes: input;
}

.static .value {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Inverse modification for input */
.inverse {
  composes: input;
}

.inverse .icon:first-child {
  color: var(--themeIconDisabledInverse);
}

.inverse .label {
  color: var(--themeTextColorLabelInverse);
}

.inverse .value {
  color: var(--themeTextColorInverse);
}

.inverse .value::placeholder {
  color: var(--themeTextColorDisabledInverse);
}

.inverse .container {
  border-color: var(--themeInputBorderInverse);
}

.inverse .description {
  color: var(--themeTextColorMutedInverse);
}

.inverse .icon {
  color: var(--themeIconInverse);
}

/* Muted modification for input */
.muted .value::placeholder {
  color: var(--themeTextColorMuted);
}

/* stylelint-disable-next-line selector-max-specificity */
.value:-webkit-autofill::placeholder,
.inverse .value:-webkit-autofill::placeholder,
.muted .value:-webkit-autofill::placeholder,
.input:not(.withValue):has(.container .value:-webkit-autofill) > .label {
  color: transparent;
}

.focused .muted .iconColor {
  color: var(--themeIcon);
}

.invisible {
  visibility: visible !important; /* stylelint-disable-line */
}

/* Disable resize ability */
.noResize {
  composes: input;
}

.noResize .value {
  resize: none;
}

/**
 * 2 identical keyframes since CSS doesn't
 * replay the same animation if it adds with new classname.
 */
@keyframes focus {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blur {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
