/* Checkbox */
.default {
  display: flex;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
}

/* Elements */
.input {
  /* Real checkbox or radio box. Must be hidden */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
}

.icon {
  display: block;
  position: relative;
  z-index: 2;
  font-size: 24px;
  background: transparent;
  color: var(--themeIcon);
  transition: var(--themeTransition);
}

.container {
  position: relative;
  flex-shrink: 0;
}

.container::after,
.container::before {
  /* Ripple */
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  content: "";
  border-radius: 50%;
  background: transparent;
  will-change: transform;
}

.container::before {
  opacity: 0.25;
}

.container::after {
  z-index: 0;
  transition: var(--themeTransition);
}

.label,
.labelWrap {
  margin-left: 20px;
  flex-grow: 1;
}

.label {
  color: var(--themeTextColorLabel);
}

/* States */
.standart.active .icon,
.standart.withoutAnimated.active .icon {
  color: var(--themeIcon);
}

.hover .container::after {
  background: var(--themeCardLight);
  transform: scale(2);
}

.active.hover .container::after {
  background: var(--skinPrimaryLite);
}

.active .icon {
  color: var(--skinPrimary);
}

/* Focused state */
/* stylelint-disable selector-max-specificity */
.focused .container::before,
.default:active .container::before {
  transform: scale(2);
  background: var(--themeTextColorLabel);
  transition: all 300ms var(--themeTransitionEase);
}

/* For payment  */
.withoutAnimated.default:active .container::before,
.withoutAnimated.active.focused .container::before {
  background: transparent;
}

.active.focused .container::before {
  background: var(--skinPrimary);
}

.animated .container::before {
  background: var(--themeTextColorLabel);
  animation: wave 300ms linear forwards;
}

.animated.active .container::before {
  background: var(--skinPrimary);
}

.muted .icon,
.muted.active .icon {
  color: var(--themeIconMuted);
}

.muted.animated.active .container::before {
  background: var(--skinPrimary);
}
/* stylelint-enable */

/* Disabled state */
.disabled {
  cursor: default;
  pointer-events: none;
}

.disabled .icon {
  color: var(--themeIconDisabled);
}

.disabled:active .container {
  display: none;
}

.disabled.active .icon {
  color: var(--themeIconDisabled);
}

/* Inverse state */
.inverse {
  composes: default;
}

.inverse .icon {
  color: var(--themeIconInverse);
}

.inverse .label {
  color: var(--themeTextColorLabelInverse);
}

/* stylelint-disable */
.inverse.animated .container::before,
.inverse.focused .container::before {
  background: var(--themeIconInverse);
}

.active.inverse.hover .container::after,
.inverse.hover .container::after {
  background: var(--themeCardLightInverse);
}
/* stylelint-enable */

.inverse.active .icon {
  color: var(--skinPrimary);
}

/* stylelint-disable-next-line */
.inverse.active .icon::after {
  color: var(--themeCardInverse);
}

.inverse.disabled .icon {
  opacity: 1;
  background-color: var(--themeIconDisabledInverse);
}

/* Row modification */
.row {
  composes: default;
  width: 100%;
  flex-direction: row-reverse;
}

.row .label,
.row .labelWrap {
  margin-left: 0;
  margin-right: 8px;
}

/* need in profile only for set up checkbox and content to top of container */
.baseline {
  align-self: baseline;
  padding-top: 3px;
}

/* Used in case if we must have only singe input with text */
.singleInput .label,
.singleInput .labelWrap {
  margin-left: 10px;
}

/* Used in case if there is input without text. Avoid flex etc. */
.single {
  composes: default;
  display: block;
}

/* When multiline text must wrap input */
.flowInput {
  composes: default;
  display: inline;
}

.flowInput .container {
  float: left;
  margin-right: 10px;
}

.flowInput .label,
.flowInput .labelWrap {
  margin-left: 0;
}

/* When need render errorText in label like on dnsmpi */
.labelWrap .label {
  margin: 0;
}

/* When action item must be aligned. Default is center */
.top {
  composes: default;
  align-items: flex-start;
}

.center {
  composes: default;
  align-items: center;
}

.bottom {
  composes: default;
  align-items: flex-end;
}

.bottom .container {
  padding-bottom: 2px;
}

/* Size modifications */
.small {
  composes: default;
  min-width: 12px;
  min-height: 12px;
}

.small .container {
  width: 12px;
  height: 12px;
}

.small .icon {
  font-size: 12px;
}

.medium {
  composes: default;
  min-width: 14px;
  min-height: 14px;
}

.medium .container {
  width: 14px;
  height: 14px;
}

.medium .icon {
  font-size: 14px;
}

.small .iconContainer,
.medium .iconContainer {
  vertical-align: top;
  margin: 0;
}

@keyframes wave {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
    opacity: 0.25;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
